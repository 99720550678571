import React, { useState, useEffect, Suspense, lazy } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import "../App.scss";
import { IS_FAVOURITE_LOGIN, IS_TRIIVCO_LOGIN, IS_EMPOWER_LOGIN } from "../config/constant";

// Lazy load common components
const Header = lazy(() => import("../view/common/Header"));
const Footer = lazy(() => import("../view/common/Footer"));
const NotFound = lazy(() => import("../view/not-found/NotFound.js"));

const App = ({ loggedIn, authUser }) => {
  const [routes, setRoutes] = useState(null);

  // Helper function to load routes based on login state, with await
  const loadRoutes = async () => {
    // Initialize fileName4import with a valid path
    let fileName4import="",module="";
    if (IS_TRIIVCO_LOGIN()) {
      fileName4import = "./triv_routes";
    } else if (IS_FAVOURITE_LOGIN()) {
      fileName4import = "./fav_routes";
    } else if (IS_EMPOWER_LOGIN()) {
      fileName4import = "./emp_routes";
    } else {
      fileName4import = "./oto_routes";
    }
    fileName4import = (!loggedIn) ? "./before_login" : fileName4import;
    console.log("Importing routes from:", fileName4import);
    try {
      // Dynamically import the appropriate routes file
      module = await import(`${fileName4import}`);
      setRoutes(module.default); // Set the routes after the module is imported
    } catch (error) {
      console.error("Error loading routes:", error);
    }
  };
  

  useEffect(() => {
    loadRoutes(); // Load routes when login or user data changes
  }, [loggedIn, authUser]);

  // Show a loader until routes are loaded
  if (!routes) {
    return <div className="loader is-active">Loading routes...</div>;
  }
  console.log("routes",routes);
  const currentPath = window.location.pathname;

  return (
    <BrowserRouter>
      <Suspense fallback={<div className="loader is-active">Loading...</div>}>
        <div>
          {loggedIn && currentPath !== "/remove-info" && <Header />}
          <Switch>
            {routes.map(({ path, Component, exact }) => (
              <Route key={path} path={path} component={Component} exact={exact} />
            ))}
            <Route component={NotFound} />
          </Switch>
          {loggedIn && <Footer />}
        </div>
      </Suspense>
    </BrowserRouter>
  );
};

const mapStateToProps = ({ user }) => ({
  loggedIn: user.loggedIn,
  authUser: user.authUser,
});

export default connect(mapStateToProps, null)(App);
